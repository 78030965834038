.login-root {
  background: #ebebeb;
  height: 100vh;
}

.login-container h3,
p,
label {
  color: #4a4a4a;
}

.login-container h3 {
  font-weight: 600;
}

.login-container a {
  color: #474747;
  font-weight: 500;
}

.login-container a:hover {
  color: #474747;
  opacity: 0.8;
}

.login-container {
  box-sizing: border-box;
  position: absolute;
  padding: 0.7rem;
  /* width: 1106px; */
  /* width: 57vw; */
  /* width: max(57vw, 750px); */
  width: 900px;
  min-height: 500px;
  background: #0C273D;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.login-download {
  margin-top: 2.5rem;
}

.login-download .download-title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
}

.login-download .download-links {
}

.login-download .download-links a {
  display: inline-block;
  color: #ffffff;
  text-decoration-line: underline;
  font-size: 17px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.login-download .download-links a:nth-child(2) {
  border-left: 1px solid#f49244;
  border-right: 1px solid#f49244;
}

@media (max-width: 991px) {
  .login-container {
    width: 100%;
  }
}

.left-side {
  /* background: grey; */
  border-radius: 10px;
}

.left-side h1 {
  color: white;
  /* font-size: ; */
  font-weight: 700;
  padding-top: 2rem;
}

.right-side {
  /* Rectangle 1673 */

  /* position: absolute; */
  /* width: 553px;
height: 676px; */
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 10px;
  padding: 1rem;
}

.wrapper-container {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
