/* .clear-line tbody tr:last-child {
  border-bottom: 0;
  border-top: 0;
  border-bottom: none;
  border-top: none;
} */

.agentDetailsTable {
  margin-top: 2rem;
  background: #f3f3f3;
  color: black;
  border-radius: 15px;
  margin-bottom: 2rem;
}
.darkSecondaryWrapper .agentDetailsTable {
  color: rgb(255, 255, 255);
}

@media (max-width: 1161px) {
  .agentDetailsTable {
    width: 1200px;
  }
}

.borderRight {
  border-right: 1px solid #a9a9a9;
}

.tableKey {
  padding: 0.45rem;
  padding-left: 8rem !important;
}
.tableKey2 {
  padding: 0.45rem;
}

@media (max-width: 1387px) {
  .tableKey {
    padding-left: 4rem !important;
  }
}

.tableValue {
  padding: 0.45rem;
  margin-left: 3rem !important;
}

/* .softwareDetails table thead{
  background-color: #4a4a4a;
  color: rgb(238, 238, 238);
}
.softwareDetails table thead tr th:nth-child(1){
  border-top-left-radius: 10px;
}
.softwareDetails table thead tr th:last-child{
  border-top-right-radius: 10px;
}

.softwareDetails table tbody tr:last-child{
  border-bottom: 0px solid;
  border-top-left-radius: 10px;
}
.softwareDetails table tbody tr:last-child td:first-child{
  border-top-left-radius: 10px;
} */

.input-container {
  position: relative;
}

.custom-input {
  padding-right: 45px; /* Adjusted to ensure there's enough space for the tick mark */
  border-radius: 0 5px 5px 0; /* Right edges rounded, left edges sharp */
}

.tick-box {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 43px; /* Based on the provided width */
  height: calc(100% - 2px); /* Adjust height to fit perfectly with the input */
  background-color: #0c273d;
  border-radius: 0 5px 5px 0; /* Right edges rounded, left edges sharp */
}
.darkSecondaryWrapper .tick-box {
  background-color: #f3f3f3;
}
.darkSecondaryWrapper .tick-box:after {
  border: solid #0C273D;
  border-width: 0 4px 4px 0;
}
.tick-box:hover {
  opacity: 0.9;
}

.tick-box:after {
  content: '';
  position: absolute;
  left: 18px;
  top: 45%;
  transform: translateY(-50%) rotate(45deg);
  width: 10px;
  height: 18px;
  border: solid white;
  border-width: 0 4px 4px 0;
}

.custom-input[disabled] + .tick-box {
  background-color: #d4d4d4;
}

.custom-input[disabled] + .tick-box:after {
  border-color: white;
}
