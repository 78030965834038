/* common fix for react-sliding-pane */
/* .input-group-append .btn {
  z-index: 0;
} */

/* .custom-overlay-slide-pane {
  z-index: 3;
}

.custom-slide-pane .slide-pane__header {
  background: #5a5c69;
  color: white;
} */

/* Custom Utility Classes */
.fw-600 {
  font-weight: 600;
}

/* to override default browser text selection */
.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}

/**************************/

.paginationComponent {
  display: inline-block;
}

.paginationComponent button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.paginationComponent button i {
  /* Adjust the color as per your preference */
  /* color: #000;  */
}

.tabs-container {
  /* Add container styles if needed */
}


.tabs-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0; /* Background color for the tab navigation */
  border-bottom: 1px solid #a9a9a9;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.darkSecondaryWrapper .tabs-nav{
  background-color: #0C273D; 
}
.darkSecondaryWrapper .tabs-nav .tab{
 color: #fff;
}
.darkSecondaryWrapper .tabs-nav .tab.active {
  color: #fff;
  /* font-weight: 700; */
  border-bottom: 4px solid #fff;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
}

.tab.active {
  color: #0C273D;
  /* font-weight: 700; */
  border-bottom: 6px solid #0C273D;
}

.tab-content {
  /* Add styles for the tab content if needed */
  min-height: 480px;
}

.tab-table-container {
  /* 1.) Text font: Helvetica Neue
2.) Text style: Light
3.) Font Size: 20pt
4.) Text color: #4a4a4a */
  padding: 1rem;
  font: 'Helvetica Neue';
  color: #4a4a4a;
}

.customTable-2 {
  margin-top: 2rem;
  background: rgb(235, 235, 235);
  color: black;
  border-radius: 15px;
  margin-bottom: 2rem;
}

@media (max-width: 1161px) {
  .customTable-2 {
    width: 900px;
  }
}

table.customTable-3 thead {
  background-color: #fff !important;
  border-bottom: 1px solid #9c9c9c;
}

table.customTable-3 thead tr th {
  color: #4a4a4a;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12pt;
  font-weight: 600;
  text-align: center;
  padding: 0.75rem;
}

table.customTable-3 tbody tr {
  background-color: #fff !important;
}

.noRecords {
  background: #fff;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
  margin-top: 1rem;
}
.noRecordsDark{
  background: #0a0f1a61;
  color: #e8e8e8 !important;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
  margin-top: 1rem;
}

.newOverLayDesign{
  width: 100%;
  position: absolute;
  z-index: 3;
  top: calc(0px + 23px);
  overflow-x: scroll;
}
.newOverLayDesign::-webkit-scrollbar {
  display: none;
}
/* For overriding default checkbox styling */
.form-check {
  padding-left: 0px !important;
}

.form-check-input {
  display: none;
}

.form-check-input + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
}

.form-check-input + label:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: 0;
  border: 1px solid #cccccc;
  border-radius: 5px;
  background-color: #fff;
  transition: all 0.2s;
}

.form-check-input:checked + label:before {
  background-color: #e29300;
}

.form-check-input:disabled + label:before {
  border-color: #dcdcdc;
  background-color: #cdcdcd;
}

.form-check-input:disabled:not(:checked) + label:before {
  background-color: #f2f2f2;
}

.form-check-input:checked + label:after {
  content: '';
  position: absolute;
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Used in System Health for previous design */
.crossTable {
  /* border-collapse: separate; */
  /* Removes the default spacing between cells */
  /* border-spacing: 0;  */
  /* width: 200px;  Adjust as necessary */
  margin: 20px auto;
  table-layout: fixed; /* Ensures equal column widths */
}

.crossTable th,
.crossTable td {
  /* width: 50%;  Split the table into two equal columns */
  /* Adjust as necessary */
  height: 50px;
  padding: 0; /* Reset default padding */
  border: none; /* Reset default borders */
}

.crossTable th {
  border-bottom: 1px solid black;
}

.crossTable th:first-child,
.crossTable td:first-child {
  border-right: 1px solid black; /* Add right border only to the first column */
}

.customCalenderPickerIcon {
  position: absolute;
  top: 19px;
  right: 10px;
  transform: translateY(-50%);
  z-index: 1 !important;
  width: 26px;
  height: 25px;
  cursor: pointer;
  background: transparent;
  /* background: white; */
  /* border: 1px solid red; */
}

.dateRangeCard {
  background: white;
  border-radius: 5px;
  border: 1px solid rgb(124, 124, 124);
  position: absolute;
  top: 15px;
  z-index: 1;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */

  /* min-height: 150px; */
}

.dateRangeCard .react-calendar {
  /* default styling */
  /* width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em; */

  width: 308px;
  border-bottom: none;
  /* margin-bottom: 13px;
  border-radius: 5px;
  border: 1px solid #cccccc; */
} 

.darkSecondaryWrapper .dateRangeCard {
  background-color: #1e293b !important;
  /* background-color: #1e293b;  */
}

.darkSecondaryWrapper .dateRangeCard .react-calendar {
  background-color: #1e293b !important;
}


.darkSecondaryWrapper .dateRangeCard .react-calendar__navigation__label__labelText {
  color: #e8e8e8 !important;
}
.darkSecondaryWrapper .dateRangeCard .react-calendar__navigation__label__labelText:hover {
  color: #000 !important;
}

.darkSecondaryWrapper .dateRangeCard .react-calendar__navigation__arrow {
  color: #e8e8e8 !important;
}
.darkSecondaryWrapper .dateRangeCard .react-calendar__navigation button:enabled:hover, 
.darkSecondaryWrapper .dateRangeCard .react-calendar__navigation button:enabled:focus,
.darkSecondaryWrapper .dateRangeCard button:enabled:hover,
.darkSecondaryWrapper .dateRangeCard .react-calendar__navigation__label:hover calendar__navigation__label__labelText  { 
  color: #000 !important;
}

.darkSecondaryWrapper .dateRangeCard .react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, 
.darkSecondaryWrapper .dateRangeCard .react-calendar__century-view__decades__decade--neighboringCentury {
  color: #d6d2d27f !important;
}

.darkSecondaryWrapper .dateRangeCard .react-calendar__month-view__days__day,
.darkSecondaryWrapper .dateRangeCard .react-calendar__navigation__label,
.darkSecondaryWrapper .dateRangeCard .react-calendar__year-view__months__month {
  color: #e8e8e8;
}

.darkSecondaryWrapper .dateRangeCard .react-calendar__month-view__days__day--weekend {
  color: red;
}

.darkSecondaryWrapper .dateRangeCard .react-calendar__navigation__label:enabled:hover react-calendar__navigation__label__labelText  {
  color: #000 !important;
}

/* .react-calendar__navigation button:enabled:hover */
/* #92939b */
.darkSecondaryWrapper .dateRangeCard .react-calendar__month-view__weekdays__weekday {
  color: #9d9ea1;
}

.css-1dimb5e-singleValue{
  margin-left: 7px !important;
}