.modal-close-button .close {
  color: white;
  opacity: 0.60;
}

.modal-close-button .close:hover {
  color: white;
  opacity: 0.90 !important;
}

.custom-slide-pane {
  overflow-x: auto;
  overflow-y: auto;
  width: 650px !important;
  height: calc(100% - 107px);
  margin-top: 60px;
}
@media (max-width:657px){
  .custom-slide-pane{
    width: 100% !important;
  }
}

.custom-overlay-slide-pane {
  z-index: 3;
}

.custom-slide-pane .slide-pane__header {
  background: #fac33f;
  color: #000;
  display: none;
}
.slide-pane__content{
  padding: 0px !important;
}
.modalHeader{

  display: flex;
  align-items: center;
  background: #0C273D;
  justify-content: space-between;
  padding: 20px 40px;

}
.modalHeader h3{
  margin-bottom: 0px;
  font-weight: 700;
font-size: 23px;
color: #FFFFFF;

}
.modalHeader i{
  cursor: pointer;
  font-size: 20px;
}
.modalHeader i:before{
  color: #fff;

}
.modalContent{
  padding: 30px 40px;

}
.modalContent label{
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600 !important;
  color: #4A4A4A;
}
.darkSecondaryWrapper .modalContent .applyButton{

 color: #0C273D !important;
  background: #FFFFFF !important;
}
.darkSecondaryWrapper .modalContent .closeButton{
  color: #0C273D !important;
  background: #FFFFFF !important;
}
.modalContent .closeButton{
  background: #0C273D;
  color: #FFFFFF;
  border-radius: 5px;
    padding: 6px 17px;
    text-transform: capitalize;
    border: none;
}
.modalContent .applyButton{
  color: #FFFFFF;
  border-radius: 5px;
    padding: 6px 17px;
    text-transform: capitalize;
    border: none;
    background: #0C273D;
}
.modalContent .applyButton:disabled {
  background: #0c273d79;
  opacity: 0.7;
}
.activeLabel label{
  font-weight: 400 !important;
}
.custom-slide-pane .slide-pane__content {
  overflow-x: auto;
  overflow-y: auto; 
}

.slide-pane-footer {
  position: fixed;
  bottom: 15px;
}

.table-row:hover {
  background: rgb(238, 238, 238);
  cursor: pointer;
}

.filterShow {
  display: block;
  /* opacity: 1; */
}

.filterHide {
  /* display: none; */
  /* opacity: 0; */
  display: none !important;
  /* visibility: hidden; */
} 